import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import DesktopHeader from './desktop-header';
import MobileHeader from './mobile-header';
import { breakpoints } from '../primitives/tokens';
import { colors } from '../primitives/colors';
import { useLanguage } from '../language/language-provider';
import CustomStructuredTextRenderer from '../structured-text/custom-structured-text-renderer';
import Typography from '../primitives/typography';
import Container from '../primitives/grid/container';

export const HeaderContext = React.createContext<boolean>(false);

const Header = (): JSX.Element => {
    const headerEl = React.useRef<HTMLElement>(null);
    const positionRef = React.useRef(0);
    const visibleRef = React.useRef(true);
    const apexRef = React.useRef(false);
    const [visible, setVisible] = React.useState(true);
    const [overApex, setOverApex] = React.useState(false);
    const { language } = useLanguage();

    // eslint-disable-next-line consistent-return
    React.useEffect(() => {
        const hEl = headerEl.current;
        if (hEl) {
            const { top, height } = hEl.getBoundingClientRect();
            const topZ = top + height - 100;
            const handleScroll = (): void => {
                const moving = window.pageYOffset;
                const isVis = positionRef.current > moving || moving < topZ;
                const isOverApex = moving > topZ;
                if (visibleRef.current !== isVis) {
                    visibleRef.current = isVis;
                    setVisible(isVis);
                }
                if (apexRef.current !== isOverApex) {
                    apexRef.current = isOverApex;
                    setOverApex(isOverApex);
                }
                positionRef.current = moving;
            };
            window.addEventListener('scroll', handleScroll);
            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        }
    }, []);

    const data = useStaticQuery<Queries.AnnouncementBarQuery>(graphql`
        query AnnouncementBar {
            au: datoCmsAnnouncementBar(locale: "en-AU") {
                showAnnouncement
                richText {
                    blocks
                    links
                    value
                }
            }
            uk: datoCmsAnnouncementBar(locale: "en-GB") {
                showAnnouncement
                richText {
                    blocks
                    links
                    value
                }
            }
            us: datoCmsAnnouncementBar(locale: "en-US") {
                showAnnouncement
                richText {
                    blocks
                    links
                    value
                }
            }
        }
    `);

    const announcement = data[language as 'au' | 'uk' | 'us'];

    return (
        <HeaderContext.Provider value>
            {announcement && announcement.showAnnouncement && (
                <Typography
                    override
                    css={{
                        padding: '10px 0',
                        textAlign: 'center',
                        zIndex: 3000,
                        position: 'relative',
                        pointerEvents: 'all',
                        'i, em, b, strong': {
                            color: `${colors.shadesWhite}!important`,
                        },
                    }}
                    fontSize={{
                        dt: 1322,
                    }}
                    backgroundColor="primaryOrange"
                    color="shadesWhite"
                >
                    <Container>
                        <CustomStructuredTextRenderer data={announcement.richText} />
                    </Container>
                </Typography>
            )}
            <header
                ref={headerEl}
                css={{
                    color: 'var(--header-color-1, var(--color-primary-dark-purple))',
                    '.logo': {
                        color: 'var(--header-color-2, var(--color-primary-orange))',
                        transition: 'color 0.2s',
                    },
                }}
                style={{
                    position: 'sticky',
                    top: '-1px',
                    zIndex: 100,
                    pointerEvents: 'auto',
                    '--header-color-1': overApex ? colors.primaryDarkPurple : undefined,
                    '--header-color-2': overApex ? colors.primaryOrange : undefined,
                    '--header-color-3': overApex ? colors.primaryOrange : undefined,
                    '--header-color-4': overApex ? colors.shadesWhite : undefined,
                    '--header-color-5': overApex ? colors.primaryLightOrangeHover : undefined,
                    '--header-color-6': overApex ? colors.primaryPurple : undefined,
                    '--header-color-7': overApex ? colors.grey100 : undefined,
                }}
            >
                <div
                    css={{
                        zIndex: '10',
                        top: '0',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'relative',
                        [breakpoints.headerMb]: {
                            display: 'none',
                        },
                    }}
                >
                    <div css={{
                        boxShadow: '0 7px 24px 0 rgba(0, 0, 0, 0.14)',
                        backgroundColor: colors.shadesWhite,
                        opacity: overApex ? 1 : 0,
                        pointerEvents: 'none',
                        position: 'absolute',
                        height: '100%',
                        width: '100%',
                        transition: '0.3s',
                    }}
                    />
                    <DesktopHeader overApex={overApex} />
                </div>
                <div
                    css={{
                        zIndex: '10',
                        top: '0',
                        width: '100%',
                        display: 'none',
                        position: 'relative',
                        [breakpoints.headerMb]: {
                            display: 'block',
                        },
                    }}
                >
                    <div css={{
                        boxShadow: '0 7px 24px 0 rgba(0, 0, 0, 0.14)',
                        backgroundColor: colors.shadesWhite,
                        opacity: overApex ? 1 : 0,
                        pointerEvents: 'none',
                        position: 'absolute',
                        height: '100%',
                        width: '100%',
                        transition: '0.3s',
                    }}
                    />
                    <MobileHeader
                        hasAnnouncement={Boolean(!overApex && announcement && announcement?.showAnnouncement)}
                    />
                </div>
            </header>
        </HeaderContext.Provider>
    );
};

export default Header;
